<template>
  <main id="content" role="main">
    <section class="visual_intro">
      <!-- 다른곳과 동일하게. 넣어야 할 이미지는 /assets/images/contest/visual_wall.png 로 넣어 두었습니다.-->
      <div class="visual_wall">
      </div>
      <div class="intro_title">
        <strong class="title">K-STARTUP TO THE WORLD</strong>
      </div>
    </section>
    <section class="report_group">
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">{{ $t('content.accelerating.Common.txt39') }}</h2>
          <p class="description">
            {{ $t('content.accelerating.Common.txt32') }}<br />
            {{ $t('content.accelerating.Common.txt33') }}<br />
            {{ $t('content.accelerating.Common.txt34') }}
          </p>
        </div>
        <!-- TODO: /contents/list 에 있는 정렬버튼과 동일하게 추가 -->
        <div class="contest-sort">
          <div class="order_flag" v-if="dataList && dataList.length > 0">
            <button role="button" aria-haspopup="listbox" v-text="rangeText"
                :aria-expanded="rangeShow" class="current" @click="rangeCol"></button>
              <ul class="flag_list" role="listbox">
                  <li class="flag_item" role="presentation">
                      <button class="flag" role="option"
                        @click="rangeCol">
                        {{ $t('content.news.list.sort.dates') }}
                      </button>
                  </li>
                  <li class="flag_item" role="presentation">
                      <button class="flag" role="option"
                        @click="rangeCol">
                        {{ $t('content.news.list.sort.registrated') }}
                      </button>
                  </li>
              </ul>
          </div>
        </div>
        <div class="contest-list">
          <ul>
            <template v-if="dataList && dataList.length > 0">
              <li  v-for="contest in dataList">
                <div class="thumb" @click="$router.push(`/contest/detail/${contest.id}`)">
                  <span :style="`background-image: url(${contest.thumbnailFileUrl || example});`"></span>
                </div>
                <div class="info">
                  <span class="title">{{ contest. title }}</span>
                  <!-- TODO: type-ready : 모집전 , type-ing : 모집중 , type-finish : 모집종료-->
                  <span v-if="contest.state === 1" class="status type-ing">{{ $t('content.accelerating.Common.txt36') }}</span>
                  <span v-else-if="contest.state === 2" class="status type-ready">{{ $t('content.accelerating.Common.txt35', [ contest.startDate ]) }}</span>
                  <span v-else-if="contest.state === 3" class="status type-finish">{{ $t('content.accelerating.Common.txt37') }}</span>
                  <span v-else-if="contest.state === 0" class="status">{{ $t('content.accelerating.Common.txt38') }}</span>
                  <!-- TODO: 상시모집일 경우 모집기간 없음 -->
                  <span v-if="!contest.isAlwaysRecruitment" class="date">
                    <template v-if="contest.state !== 2">
                      {{ $t('content.accelerating.Common.txt20') }} {{ contest.startDate }} ~ {{ contest.endDate }}
                    </template>
                  </span>
                  <span v-else class="date"></span>
                  <a :href="`/contest/detail/${contest.id}`" class="link">
                    {{ $t('content.accelerating.Common.txt31') }}
                  </a>
                </div>
              </li>
            </template>
            <template v-else>
              <li>
                <div class="info">
                  <span class="title" style="text-align: center; width: 100%; padding: 50px;">{{ $t('content.accelerating.Common.txt40') }}</span>
                </div>
              </li>  
            </template>
          </ul>
        </div>
        <Pagination ref="Pagination" v-if="dataList && dataList.length > 0"/>
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import Pagination from '@/components/base/Pagination.vue';
import { APIs } from '@/common/portalApi';

export default {
  name: 'ContestList',
  components: {
    Pagination,
  },
  mixins: [
    mixinHelperUtils,
  ],
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      example : 'https://staginggangnam.dreamplus.asia/assets/images/company/all_noimg.png',
      dataList: [],
      search: {
        page: 1,
        size: 12,
        order: 'desc',
        data: {
          state: 9
        }
      },
      totalCount: 0,
      count: 0,
      rangeText: this.$t('content.news.list.sort.dates'),
      rangeShow: false
    };
  },
  mounted(){
    this.getList();
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  },
  methods: {
    init() {
      this.dataList = [];
      this.search.page = 1;
      this.getList(1);
    },
    /**
    * 순서 정렬
    */
    rangeCol(e) {
      this.rangeShow = !this.rangeShow;
      if (e.target.classList.value !== 'flag') return;
      console.log(e.target, e.target.innerHTML, this.$t('content.news.list.sort.dates'))
      if (e.target.innerHTML.trim() === this.$t('content.news.list.sort.dates')) {
        this.rangeText = e.target.innerHTML;
        this.search.order = 'desc';
      } else if (e.target.innerHTML.trim() === this.$t('content.news.list.sort.registrated')) {
        this.range = [false, true, false];
        this.rangeText = e.target.innerHTML;
        this.search.order = 'asc';
      } else if (e.target.innerHTML.trim() === this.$t('content.news.list.sort.popularity')) {
        this.range = [false, true, false];
        this.rangeText = e.target.innerHTML;
        this.search.order = 'popularity';
      }
      this.init();
    },
    getList (page) {
      if (!page) {
        page = 1
      }
      this.search.page = page;
      this.portalApiClient({
        url: APIs.CONTEST_LIST,
        method: 'post',
        data: this.search
      }).then(({ data }) => {
        if (data.result) {
          this.dataList = data.list;
          this.count = data.count;
          this.totalCount = data.totalCount;
          // page
          this.$refs.Pagination.loadPage(this.count, this.search.size, this.search.page);
        } else {
          this.showAlert(data.message);
        }
      });
    }
  }
};
</script>

<style scoped>
.order_flag .current[aria-expanded=true]::after {
    top: 25px;
    transform: scaleX(0.75) rotateX(180deg);
}
.thumb {
  cursor: pointer;
}
</style>
